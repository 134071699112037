import styled from 'styled-components';
import { Button, Drawer } from 'antd';

export const OpenButton = styled(Button)`
  padding: 0;
  width: 40px;
  height: 36px;
  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;

  display: grid;
  place-items: center;
`;

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    background: #fff;
  }

  .ant-menu-item {
    font-weight: 600;
    height: 50px;

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }

    &.ant-menu-item-only-child {
      height: 50px;
    }
  }

  .ant-menu-submenu {
    font-weight: 600;

    .ant-menu-submenu-title {
      height: 50px;
    }

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }
  }
`;

export const LogoutButton = styled.button`
  margin: 8px 0;
  padding: 0 24px;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  font-weight: 600;

  display: flex;

  > p {
    margin: 0 0 0 10px;
    color: #b1b0b5;
    font-size: 0.8125rem;
  }
`;
