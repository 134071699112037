import styled from 'styled-components';
import { AutoComplete } from 'antd';

export const CustomAutoComplete = styled(AutoComplete)`
  margin-right: 10px;

  flex: 1;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid transparent;
    background: transparent;

    &:hover {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    &:focus-within {
      background: #fff;
    }

    input {
      &::selection {
        background: ${({ theme }) => theme.colors.primary};
      }
    }

    @media screen and (max-width: 768px) {
      border: 1px solid #ccc;
      background: #fff;
    }
  }
`;
