import React from 'react';
import { PageHeader } from 'antd';
import { useLocation } from 'react-router-dom';

import { getPageTitle } from '@/components/Header/constants';

const Header = () => {
  const { pathname } = useLocation();

  const title = getPageTitle(pathname);

  return title ? (
    <PageHeader
      className="page-header"
      title={getPageTitle(pathname)}
      ghost={false}
      backIcon={false}
    />
  ) : null;
};

export default Header;
