import axios from 'axios';
import Swal from 'sweetalert2';

const api = axios.create();
const apiLogin = axios.create();

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: 'Sessão expirada',
          text: 'Sua sessão está expirada. Por esse motivo, será necessário que logue novamente na aplicação.',
          confirmButtonText: 'Logar novamente',
          confirmButtonColor: '#9a8426',
        }).then(result => {
          sessionStorage.clear();

          window.location.href = '/login';
        });
      }
    }
    let objResponse = error?.response?.data?.errors;
    if (objResponse) {
      Object.keys(objResponse).array.forEach(key => {
        error.response.data = objResponse[key];
      });
    }
    objResponse = error?.response?.data?.message;
    if (objResponse) {
      error.response.data = objResponse;
    }
    return Promise.reject(error);
  },
);

apiLogin.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const objResponse = error?.response?.data?.errors;
    if (objResponse) {
      Object.keys(objResponse).forEach(key => {
        error.response.data = objResponse[key];
      });
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use(function (config) {
  const token = `Bearer ${sessionStorage.getItem('@Frotabank:token')}`;
  config.headers.Authorization = token ?? '';
  if (token === 'Bearer null') {
    window.location.href = '/login';
  }
  config.headers['Ocp-Apim-Subscription-Key'] =
    process.env.REACT_APP_SUBSCRIPTION_KEY;
  return config;
});

apiLogin.interceptors.request.use(function (config) {
  config.headers['Ocp-Apim-Subscription-Key'] =
    process.env.REACT_APP_SUBSCRIPTION_KEY;
  return config;
});

export { api, apiLogin };
