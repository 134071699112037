import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';

import useViewportWidth from '@/hooks/useViewportWidth';

import AppRoutes from '@/routes/app.routes';

import TopHeader from '@/components/TopHeader';
import Sidebar from '@/components/Sidebar';
import Footer from '@/components/Footer';
import Header from '@/components/Header';

const CHOOSE_PARTNER_ROUTE = '/escolhaParceiro';
const DASHBOARD_ROUTE = '/dashboard';

const AppLayout = () => {
  const { all } = useSelector(state => state.partnersReducer);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation();
  const { isMobile } = useViewportWidth();

  const defaultRoute =
    all.length === 1 ? DASHBOARD_ROUTE : CHOOSE_PARTNER_ROUTE;

  const showLayoutComponents = pathname !== CHOOSE_PARTNER_ROUTE;
  const showSidebar = showLayoutComponents && !isMobile;

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const layoutContentStyle = showSidebar
    ? {
        marginLeft: isSidebarOpen ? 200 : 80,
        transition: 'margin 0.2s',
        minHeight: '100vh',
        background: '#f8f8f8',
      }
    : { minHeight: '100vh', background: '#f8f8f8' };

  return (
    <Layout>
      {showSidebar && (
        <Sidebar isOpen={isSidebarOpen} toggleOpen={toggleSidebar} />
      )}

      <Layout style={layoutContentStyle}>
        <Layout.Content style={{ margin: showLayoutComponents ? '0 16px' : 0 }}>
          {showLayoutComponents && <TopHeader />}
          {showLayoutComponents && <Header />}
          <AppRoutes route={defaultRoute} />
        </Layout.Content>
        {showLayoutComponents && <Footer />}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
