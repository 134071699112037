import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import links from '@/constants/links';

import logoImg from '@/assets/images/logoFrotabankAltWhite.png';
import logo from '@/assets/images/logoFrotabankWhite.png';

import {
  Container,
  CustomMenuItem,
  CustomSingleMenuItem,
  CustomSubMenu,
  Logo,
} from './styles';

const Sidebar = ({ isOpen, toggleOpen }) => {
  const { active = [] } = useSelector(state => state.functionalitiesReducer);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const theme = useTheme();

  const navigate = link => {
    const differentPage = link !== pathname;

    if (differentPage) {
      push(link, true);
    }
  };

  return (
    <Container>
      <Layout.Sider
        collapsible
        collapsed={!isOpen}
        onCollapse={toggleOpen}
        theme="light"
        style={{
          borderRight: '1px solid #efefef',
          overflowY: 'auto',
          overflowX: 'hidden',
          minHeight: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Logo to="/dashboard">
          <img src={isOpen ? logo : logoImg} alt="Frotabank" />
        </Logo>
        <Menu
          mode="inline"
          selectedKeys={[pathname]}
          style={{
            borderWidth: 1,
            borderColor: theme.colors.primary,
            paddingBottom: 50,
            backgroundColor: theme.colors.primary,
          }}
        >
          {links.map(item => {
            const actions = active.map(iten => iten.action);
            const linkAutorizate = actions.indexOf(item.link);
            if (linkAutorizate !== -1 || item.link === '/dashboard') {
              if (item.subItems) {
                return (
                  <CustomSubMenu
                    key={item.id}
                    icon={item.icon}
                    title={item.title}
                    selected={
                      pathname === item.link || item.subItems.includes(pathname)
                    }
                  >
                    {item.subItems.map(subItem => {
                      const subItemActions = active.map(iten => iten.action);
                      const subItemlinkAutorizate = subItemActions.indexOf(
                        subItem.link,
                      );
                      if (subItemlinkAutorizate !== -1) {
                        return (
                          <CustomMenuItem
                            key={subItem.id}
                            onClick={() => navigate(subItem.link)}
                            selected={pathname === subItem.link}
                          >
                            {subItem.title}
                          </CustomMenuItem>
                        );
                      }
                      return null;
                    })}
                  </CustomSubMenu>
                );
              }

              return (
                <CustomSingleMenuItem
                  key={item.id}
                  icon={item.icon}
                  onClick={() => navigate(item.link)}
                  selected={pathname === item.link}
                >
                  {item.title}
                </CustomSingleMenuItem>
              );
            }

            return null;
          })}
        </Menu>
      </Layout.Sider>
    </Container>
  );
};

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default Sidebar;
