import React from 'react';
import { Button, Tooltip } from 'antd';
import { IoMdRefresh } from 'react-icons/io';
import { useTheme } from 'styled-components';

const RefreshButton = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  const theme = useTheme();

  return (
    <Tooltip title="Atualizar página">
      <Button type="text" onClick={refreshPage} style={{ padding: 0 }}>
        <IoMdRefresh size={28} color={theme.colors.primary} />
      </Button>
    </Tooltip>
  );
};

export default RefreshButton;
